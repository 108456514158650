<template>
  <b-card-code>
    <div v-if="$can('create','Waiver Type')" class="custom-search d-flex">
      <b-button variant="outline-primary" @click="showModal">
        {{ $t('Add new') }}
      </b-button>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx
        :data="getData"
        :columns="columns10"
        :filename="'filename'"
        :sheetname="'subjects'"
      >
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData"
                       :csv-title="'My_CSV'"
      >
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="pdfGenerate()">
        <b>PDF</b>
      </button>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="student_waiver_types"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex + 1 }}
        </span>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'approval_status'">
          <b-badge :variant="statusVariant(props.row.approval_status)">
            {{ status2[props.row.approval_status] }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'is_active'">
          <b-form-checkbox v-if="props.row.approval_status==1 && $can('status change','Fee Type')"
                           :name="'check-button'+props.row.originalIndex" :id="'check-button'+props.row.originalIndex"
                           @change="statusChange(props.row.id,props.row.originalIndex)" v-model="props.row.is_active"
                           :value="1" switch>
            </b-form-checkbox>
            <b-badge v-else :variant="statusVariant(props.row.is_active)">
              {{ props.row.is_active ? 'Active' : 'Deactivated' }}
            </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template>
                <!-- modal login button -->

              <template v-if="props.row.approval_status==0">
                <b-button v-if="(findAccess(props.row.id) || user.role_id===1)" variant="primary"
                          @click="approve(props.row.id)">
                  <feather-icon class="text-body " icon="CheckIcon" size="15"/>
                </b-button>
                <b-button v-if="(findAccess(props.row.id) || user.role_id===1)" variant="danger"
                          @click="decliend(props.row.id)">
                  <feather-icon class="text-body " icon="CrosshairIcon" size="15"/>
                </b-button>
                <b-button v-if="props.row.approvalable.length<1 && user.role_id===1" variant="secondary"
                          @click="showModal2(props.row)">
                  <feather-icon class="text-body " icon="GitBranchIcon" size="15"/>
                </b-button>
                <b-button v-if="findAccess(props.row.id)" variant="secondary" @click="showModal2(props.row)">
                  <feather-icon class="text-body " icon="GitBranchIcon" size="15"/>
                </b-button>
              </template>
              <b-button v-if="props.row.approval_status !==2 && $can('update','Waiver Type')"
                        @click="selectedRow(props.row)"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-modal.modal-login
                        variant="primary">
                <feather-icon icon="Edit2Icon" class="mr-50"/>
              </b-button>
              <b-button v-if="$can('update','Waiver Type')" variant="danger" @click="remove(props.row.id)">
                <feather-icon icon="TrashIcon" class="mr-50"/>
              </b-button>
              </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','15','20']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- modal login-->
    <b-modal ref="my-modal"
             hide-footer size="lg"
             :title="model_mode==='add'?$t('Add New'):$t('Edit Information')"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <form ref="add_or_update">
          <b-row>
            <b-col md="6">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Waiver type') }}</span>
                </b-card-text>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('name')"
                  rules="required"
                >
                  <b-form-input
                    v-model="name"
                    name="name"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2" v-if="model_mode=='add'">
              <b-card-text>
                <span>{{ $t('Need approval ?') }}</span>
              </b-card-text>
              <validation-provider
                #default="{ errors }"
                :name="$t('need approval')"
                rules="required"
              >
                <b-form-radio-group
                  v-model.number="approval_status"
                  :options="options"
                  stacked
                  name="approval_status"
                >
                </b-form-radio-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4" v-if="model_mode=='add' && approval_status===0">
              <b-card-text>
                <span>Select Employee </span>
              </b-card-text>
              <validation-provider
                #default="{ errors }"
                name="employee"
                rules="required"
              >
                <select v-model="employee_id" name="employee_id" class="form-control"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
                  <option :value="null">Select One</option>
                  <option v-for="(employee, key) in employees" :key="key" :id="employee.id"
                          :value="employee.id">
                    {{ employeeNameById(employee.id) + ' ' + employeeIdById(employee.id) }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-modal>
    <b-modal ref="my-modal2"
             hide-footer size="lg"
             :title="'Foreword Information'"
    >
      <b-row>
        <b-col md="12">
          <b-table-simple hover small caption-top responsive>
            <b-thead>
              <b-th class="text-center" :colspan="1">Employee ID</b-th>
              <b-th class="text-center" :colspan="1">Employee Name</b-th>
              <b-th class="text-center" :colspan="1">Comment</b-th>
              <b-th class="text-center" :colspan="1">Status</b-th>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(grade,k) in selected_row.approvalable" :key="k">
                <b-th class="text-center">{{ employeeIdById(grade.employee_id) }}</b-th>
                <b-th class="text-center">{{ employeeNameById(grade.employee_id) }}</b-th>
                <b-th class="text-center">{{ grade.comment }}</b-th>
                <b-th class="text-center">{{ status3[parseInt(grade.status)] }}</b-th>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form ref="leave_request">
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="employee"
                  rules="required"
                >
                  <select name="employee_id" v-model="employee_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                    <option :value="null">Select One</option>
                    <option v-for="(employee, key) in employees" :value="employee.id" :key="key">
                      {{ employeeNameById(employee.id) }}- {{ employee.employee_id }}
                    </option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="comment"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="comment"
                    name="comment"
                    :state="errors.length > 0 ? false:null"
                    placeholder="comment"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="forward"
              >
                {{ $t('Submit') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect,
  BModal, BRow, BCol, BFormRadioGroup, BCardText, BFormCheckbox, BFormTextarea,
  BForm, BTableSimple, BThead, BTr, BTh, BTbody,
} from 'bootstrap-vue'
import {required} from '@validations'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";
Vue.use(VueExcelXlsx);

export default {
  name: 'StudentWaiverType',
  components: {
    BCardCode,
    VueGoodTable, BFormCheckbox,
    BBadge, BFormRadioGroup, BCardText,
    BPagination, BFormTextarea,
    BForm, BTableSimple, BThead, BTr, BTh, BTbody,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BModal,
    BRow, BCol,
    ValidationProvider,
    ValidationObserver, VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      approval_status: 1,
      required,
      pageLength: 10,
      dir: false,
      model_mode: 'add',
      selected_row: {},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Approval Status',
          field: 'approval_status',
        },
        {
          label: 'Status',
          field: 'is_active',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      options: {
        0: 'yes',
        1: 'No'
      },
      status2: {
        0: 'pending',
        1: 'approved',
        2: 'canceled',
      },
      status: [{
        1: 'active',
        2: 'deactivate',
      },
        {
          1: 'light-primary',
          2: 'light-danger',
        }],
      employee_id: null,
      comment: '',
      status3: {
        0: 'Pending',
        1: 'Approved',
        2: 'Foreword',
        3: 'Declined'
      },
      columns10: [
        {
          label: 'Name',
          field: 'Name',
        },
        {
          label: 'Approval Status',
          field: 'ApprovalStatus',
        },
        {
          label: 'Status',
          field: 'Status',
        },
      ],
    }
  },
  methods: {

    selectedRow(row) {
      this.selected_row = row;
      this.name = row.name;
      this.model_mode = 'edit';
      this.$refs['my-modal'].show()
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data = new FormData(this.$refs.add_or_update);
          if (this.model_mode === 'add') {
            apiCall.post('/student/waiver/type/store', data).then((response) => {
              this.hideModal();
              //this.$store.commit('ADD_NEW_STUDENT_WAIVER_TYPE',response.data.data);
              this.$store.dispatch('GET_ALL_STUDENT_WAIVER_TYPE');
              this.$toaster.success(response.data.message);
            }).catch((error) => {
              if (error.response.status == 422) this.$toaster.error(error.response.data.errors.name);
              else this.$toaster.error(error.response.data.message);
            });
          } else {
            apiCall.post(`/student/waiver/type/update${this.selected_row.id}`, data).then((response) => {
              this.hideModal();
              this.$store.dispatch('GET_ALL_STUDENT_WAIVER_TYPE');
              this.$toaster.success(response.data.message);
            }).catch((error) => {
              if (error.response.status == 422) this.$toaster.error(error.response.data.errors.name);
              else this.$toaster.error(error.response.data.message);
            });
          }
        }
      })
    },
    approvalStatusChange(id, status, index) {
      this.$swal({
        title: 'Are you sure?',
        text: "You want to change status!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.put(`/student/waiver/type/approve${id}/orCancel${status}`).then((response) => {
            this.$store.commit('APPROVAL_STATUS_CHANGE_STUDENT_WAIVER_TYPE', [index, status]);
            this.$toaster.success(response.data.message);
          }).catch((error) => {
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    statusChange(id, index) {
      apiCall.put(`/student/waiver/type/status/change${id}`).then((response) => {
        this.$store.commit('STATUS_CHANGE_STUDENT_WAIVER_TYPE', index);
        this.$toaster.success(response.data.message);
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
        this.$store.dispatch('GET_ALL_STUDENT_WAIVER_TYPE');
      });
    },
    remove(id, index) {
      this.$swal({
        title: 'Are you sure?',
        text: "You want to delete this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.delete(`/student/waiver/type/remove${id}`).then((response) => {
            this.$store.commit('REMOVE_STUDENT_WAIVER_TYPE', index);
            this.$toaster.success(response.data.message);
          }).catch((error) => {
            this.$toaster.error(error.response.data.message);
          });
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelled',
            text: 'Your date is safe :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showModal() {
      this.$refs['my-modal'].show()
      this.selected_row = {};
      this.name = '';
      this.model_mode = 'add';
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },

    showModal2(row) {
      this.selected_row = row;
      this.$refs['my-modal2'].show();
    },
    hideModal2() {
      this.$refs['my-modal2'].hide();
    },
    decliend(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You want to declined this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, declined it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.put(`/student/waiver/type/declined${id}`).then((response) => {
            this.$store.dispatch('GET_ALL_STUDENT_WAIVER_TYPE');
            this.$swal({
              icon: 'success',
              title: 'Status Change!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }).catch((error) => {
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    forward() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data = new FormData(this.$refs.leave_request);
          apiCall.post(`/student/waiver/type/forward${this.selected_row.id}`, data).then((response) => {
            this.hideModal2();
            this.$toaster.success(response.data.message);
            this.selected_row = {};
            this.employee_id = null;
            this.comment = '';
            this.$store.dispatch('GET_ALL_STUDENT_WAIVER_TYPE');
          }).catch((error) => {
            if (error.response.status == 422) this.$toaster.error(error.response.data.errors.name);
            else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    findAccess(id) {
      let ff = this.student_waiver_types.find(item => item.id === parseInt(id));
      if (ff) {
        if (ff.approvalable.length < 1) return false;
        else return ff.approvalable[ff.approvalable.length - 1].employee_id === this.user.userable.id;
      } else return false;
    },
    /*
    *
    * method for approve item
    * */
    approve(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You want to approve!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, approve it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.put(`/student/waiver/type/approve${id}`).then((response) => {
            this.$toaster.success(response.data.message);
            this.$store.dispatch('GET_ALL_STUDENT_WAIVER_TYPE');
          }).catch((error) => {
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    pdfGenerate() {
      let title = "Wavier Type";
      let clm = ['Name'];
      let data = new FormData();
      data.append('title', title);
      data.append('columns', JSON.stringify(clm));
      data.append('data', JSON.stringify(this.getData));
      apiCall.post('/get/common/table/export/pdf', data, {responseType: 'blob'}).then((response) => {
        const content = response.headers['content-type'];
        download(response.data, 'waiver_type.pdf', content)
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1: 'light-success',
        0: 'light-danger',
        2: 'light-danger',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    student_waiver_types() {
      return this.$store.getters.student_waiver_types;
    },
    user() {
      return JSON.parse(localStorage.getItem('userData'))
    },
    ...mapGetters(['employees', 'employeeIdById', 'employeeNameById']),
    getData() {
      return this.student_waiver_types.map(item => ({
        Name: item.name ? item.name : '',
        ApprovalStatus: item.approval_status == 1 ? 'Approved' : (item.approval_status == 2 ? 'Canceled' : (!item.approval_status ? 'Pending' : '')),
        Status: item.is_active ? 'Active' : 'Inactive',
      }));
    }
  },
  created() {
    if (this.student_waiver_types.length < 1) this.$store.dispatch('GET_ALL_STUDENT_WAIVER_TYPE');
    if (this.employees.length < 1) this.$store.dispatch('GET_ALL_EMPLOYEE');
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>